<template>
  <div>
    <Breadcrumbs page_title="quiz" :items="breadcrumbs" />

    <v-container>
      <v-card class="rounded-lg">
        <v-sheet style="padding: 12px">
          <v-toolbar height="54px" class="elevation-0">
            <v-tabs
              @change="onTabChange"
              v-model="currentTab"
              class="tab-mobileres"
              color="secondary"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                class="text-capitalize"
                style="font-size: 16px; width: 150px"
                tag
                v-for="(tab, i) in Tabs"
                :key="i"
                >{{ $t(tab.title) }}</v-tab
              >
            </v-tabs>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>

          <v-tabs-items v-model="tabs" class="mt-1">
            <v-tab-item value="tab-1">
              <v-form ref="form1" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="10" md="10" lg="10">
                    <v-row class="ma-1">
                      <v-col cols="12" xs="12" sm="12" md="6">
                        <label for="quizname" class="font-style">
                          {{ $t("quizname") }}
                          <span style="color: red">*</span>
                        </label>
                        <v-text-field
                          :rules="quizNameRules"
                          style="margin-top: 7px"
                          outlined
                          dense
                          v-model="quizName"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Section List -->
                    <v-row class="ma-1">
                      <v-col cols="12">
                        <v-card v-for="(item, i) in section_Items" :key="i">
                          <v-row class="ma-1">
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <label for="section" class="font-style">
                                {{ $t("section") }}
                                <span style="color: red">*</span>
                              </label>
                              <v-text-field
                                :rules="quizSectionRules"
                                style="margin-top: 7px"
                                outlined
                                dense
                                v-model="item.sectionName"
                                hide-details="auto"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <label for="description" class="font-style">{{
                                $t("description")
                              }}</label>
                              <img
                                alt
                                v-if="section_Items.length > 1"
                                src="@/assets/deletepdf.png"
                                @click="DeleteSection(item)"
                                width="20"
                                height="20"
                                style="
                                  cursor: pointer;
                                  border: 1px;
                                  margin-left: 15px;
                                  float: right;
                                  margin-top: 1px;
                                "
                              />
                              <v-text-field
                                style="margin-top: 7px"
                                outlined
                                dense
                                v-model="item.sectionDescription"
                                hide-details="auto"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <!-- Question List -->
                          <div
                            v-for="(item, i) in WhereQuestion(item.sectionId)"
                            :key="i"
                          >
                            <v-row class="ma-1 ml-5">
                              <v-col
                                cols="12"
                                md="8"
                                style="padding-right: 30px !important"
                              >
                                <v-row>
                                  <v-text-field
                                    type="text"
                                    v-model="item.question"
                                    class="font-style"
                                    :placeholder="'Question ' + ++i"
                                    single-line
                                  >
                                    <template slot="append">
                                      <v-img
                                        class="mb-3 mr-5"
                                        v-if="item.questionImge"
                                        :aspect-ratio="16 / 9"
                                        :width="170"
                                        v-bind:src="item.questionImge"
                                      ></v-img>
                                      <v-btn
                                        fab
                                        style="
                                          background-color: #ff6060;
                                          border-radius: 8px !important;
                                          color: #ffffff;
                                          box-shadow: unset;
                                          top: 35px;
                                        "
                                        x-small
                                        right
                                        absolute
                                        class="Image_Btn"
                                        @click="item.questionImge = null"
                                        v-if="item.questionImge"
                                      >
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-text-field>

                                  <!-- Action btn image for quiz image to upload -->
                                  <v-btn
                                    v-if="item.questionImge == null"
                                    width="20"
                                    height="30"
                                    style="
                                      padding: 0px 16px;
                                      background-color: unset;
                                      box-shadow: unset;
                                      min-width: unset;
                                    "
                                    class="mt-4 ml-0"
                                    @click="
                                      ($refs.file.value = null),
                                        $refs.file.click(),
                                        (fileQuestionSelectquestionId =
                                          item.questionId)
                                    "
                                  >
                                    <v-img
                                      :aspect-ratio="16 / 9"
                                      :width="30"
                                      :height="30"
                                      src="@/assets/images/quiz_imagesmall.svg"
                                    ></v-img>
                                  </v-btn>
                                </v-row>
                              </v-col>

                              <!-- Answer Option type Multiple or Single answer!.... -->
                              <v-col
                                cols="12"
                                md="3"
                                :style="{
                                  'padding-top':
                                    item.questionImge != null ? '43px' : '12px',
                                }"
                              >
                                <!-- Choose Answer as Single Choice or Multiple Choice -->
                                <v-select
                                  :items="selectOptionTypes"
                                  v-model="item.multipleChoice"
                                  @input="SelectChooseAnswerType(item)"
                                  item-text="text"
                                  item-value="value"
                                  hide-no-data
                                  class="quiz_select"
                                  hide-details
                                  solo
                                  dense
                                >
                                  <template v-slot:selection="{ item }">
                                    <v-icon color="#4FB14E" class="mr-2"
                                      >mdi-checkbox-marked</v-icon
                                    >
                                    <span>{{ $t(item.text) }}</span>
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-icon color="#4FB14E" class="mr-2"
                                      >mdi-checkbox-marked</v-icon
                                    >
                                    <span>{{ $t(item.text) }}</span>
                                  </template>
                                </v-select>
                              </v-col>

                              <!-- Scroll page up or down action arrow!......... -->
                              <v-col
                                cols="12"
                                md="1"
                                style="padding-left: 0; padding-right: 0"
                                :style="{
                                  'padding-top':
                                    item.questionImge != null ? '43px' : '15px',
                                }"
                              >
                                <v-btn
                                  @click="scrollToBottom(item)"
                                  width="22"
                                  height="30"
                                  style="
                                    color: #ffffff;
                                    background-color: #a6cc39;
                                  "
                                  class="smallBtn"
                                >
                                  <v-icon center small>mdi-arrow-down</v-icon>
                                </v-btn>

                                <v-btn
                                  @click="scrollToTop(item)"
                                  class="ml-1 smallBtn"
                                  width="22"
                                  height="30"
                                  style="
                                    color: #ffffff;
                                    background-color: #a6cc39;
                                  "
                                >
                                  <v-icon center small>mdi-arrow-up</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-row class="ma-1">
                              <v-col>
                                <v-card>
                                  <v-list>
                                    <v-list-item-group
                                      multiple
                                      class="option_sheet"
                                    >
                                      <template
                                        v-for="(item, i) in WhereChoice(
                                          item.questionId
                                        )"
                                      >
                                        <v-list-item
                                          v-if="item"
                                          :key="item.choiceId"
                                        >
                                          <template>
                                            <v-list-item-action>
                                              <v-checkbox
                                                @click="
                                                  CheckChooseAnswerType(item)
                                                "
                                                v-model="
                                                  item.choiceAnswerMaster
                                                "
                                                color="#4FB14E"
                                              ></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content
                                              v-if="item"
                                              style="padding-right: 26px"
                                            >
                                              <!-- Show normal answer choices that could be image or text!..... -->
                                              <v-text-field
                                                v-if="!item.rule"
                                                type="text"
                                                :disabled="
                                                  question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                @mouseover="
                                                  item.show_imgBtn = true
                                                "
                                                @mouseleave="
                                                  item.show_imgBtn = false
                                                "
                                                v-model="item.choice"
                                                class="field_Style"
                                                :placeholder="'Option ' + ++i"
                                                hide-details
                                                @blur="
                                                  check_duplicate(
                                                    item,
                                                    item.choice
                                                  )
                                                "
                                              >
                                                <template slot="append">
                                                  <v-img
                                                    v-if="item.choiceImge"
                                                    :key="item.choiceId + 1"
                                                    class="mb-3"
                                                    :aspect-ratio="16 / 9"
                                                    :width="170"
                                                    v-bind:src="item.choiceImge"
                                                  ></v-img>

                                                  <v-icon
                                                    v-if="
                                                      item.choiceAnswerMaster &&
                                                      !question_Items.find(
                                                        (x) =>
                                                          x.questionId ==
                                                          item.questionId
                                                      ).showSubmitAnsBtn
                                                    "
                                                    class="mt-1"
                                                    color="#A6CC39"
                                                    right
                                                    large
                                                    >mdi-check</v-icon
                                                  >
                                                </template>
                                              </v-text-field>

                                              <!-- For show alert when answers are duplicate!!.. -->
                                              <v-text-field
                                                v-else
                                                type="text"
                                                :disabled="
                                                  question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                @mouseover="
                                                  item.show_imgBtn = true
                                                "
                                                @mouseleave="
                                                  item.show_imgBtn = false
                                                "
                                                v-model="item.choice"
                                                class="field_Style_1"
                                                :placeholder="'Option ' + ++i"
                                                hide-details
                                                @blur="
                                                  check_duplicate(
                                                    item,
                                                    item.choice
                                                  )
                                                "
                                                append-icon="mdi-alert"
                                              ></v-text-field>
                                            </v-list-item-content>

                                            <v-list-item-action v-if="item">
                                              <v-row>
                                                <v-btn
                                                  v-if="
                                                    item.choiceImge == null &&
                                                    !question_Items.find(
                                                      (x) =>
                                                        x.questionId ==
                                                        item.questionId
                                                    ).showSubmitAnsBtn
                                                  "
                                                  width="20"
                                                  height="30"
                                                  style="
                                                    padding: 0px 16px;
                                                    background-color: unset;
                                                    box-shadow: unset;
                                                    min-width: unset;
                                                  "
                                                  class="mt-1 ml-0"
                                                  @click="
                                                    ($refs.fileChoice.value =
                                                      null),
                                                      $refs.fileChoice.click(),
                                                      (fileChoiceSelectchoiceId =
                                                        item.choiceId)
                                                  "
                                                >
                                                  <v-img
                                                    :aspect-ratio="16 / 9"
                                                    :width="30"
                                                    :height="30"
                                                    src="@/assets/images/quiz_imagesmall.svg"
                                                  ></v-img>
                                                </v-btn>
                                              </v-row>
                                            </v-list-item-action>

                                            <v-list-item-action
                                              v-if="
                                                item &&
                                                WhereChoice(item.questionId)
                                                  .length > 1
                                              "
                                            >
                                              <v-btn
                                                icon
                                                v-if="
                                                  !question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                style="
                                                  cursor: pointer;
                                                  color: #ffffff;
                                                  background-color: #ff6060;
                                                  border-radius: 8px !important;
                                                  border: 1px solid
                                                    rgba(66, 66, 66, 0.05);
                                                  width: 30px;
                                                  height: 30px;
                                                "
                                                class="mt-1"
                                                @click="Delete_Option(item)"
                                                right
                                              >
                                                <v-icon>mdi-close</v-icon>
                                              </v-btn>
                                              <v-icon
                                                v-if="
                                                  item.choiceAnswerMaster &&
                                                  question_Items.find(
                                                    (x) =>
                                                      x.questionId ==
                                                      item.questionId
                                                  ).showSubmitAnsBtn
                                                "
                                                class="mt-1"
                                                color="#A6CC39"
                                                right
                                                large
                                                >mdi-check</v-icon
                                              >
                                            </v-list-item-action>
                                          </template>
                                        </v-list-item>
                                      </template>
                                      <v-divider
                                        style="
                                          border-color: #7070707f;
                                          margin-top: 10px;
                                        "
                                      ></v-divider>
                                      <div
                                        v-if="!item.showSubmitAnsBtn"
                                        class="ml-5 mt-2"
                                      >
                                        <v-btn
                                          icon
                                          size="35"
                                          @click="Add_Option(item)"
                                          style="
                                            cursor: pointer;
                                            background-color: #4fb14e;
                                            color: #ffffff;
                                            border-radius: 8px !important;
                                            width: 25px;
                                            height: 25px;
                                          "
                                        >
                                          <v-icon color="white"
                                            >mdi-plus</v-icon
                                          >
                                        </v-btn>
                                        <span
                                          style="
                                            color: #4fb14e;
                                            font-size: 18px;
                                            cursor: pointer;
                                          "
                                          class="ml-2 mt-1"
                                          @click="Add_Option(item)"
                                          >{{ $t("addoption") }}</span
                                        >
                                      </div>
                                      <div v-else class="ml-5 mt-2">
                                        <v-row class="mt-2">
                                          <v-col>
                                            <v-row>
                                              <v-text-field
                                                solo
                                                class="limited-submit ml-4 mt-3"
                                                style="
                                                  margin-top: 7px;
                                                  max-width: 65px;
                                                "
                                                hide-spin-buttons
                                                hide-details
                                                v-model="item.questionScore"
                                                type="number"
                                              ></v-text-field>
                                              <span
                                                style="
                                                  color: #424242;
                                                  font-size: 16px;
                                                  margin-left: 10px;
                                                  margin-top: 16px;
                                                "
                                                >{{
                                                  "(" + $t("score") + ")"
                                                }}</span
                                              >
                                            </v-row>
                                          </v-col>
                                          <v-col style="text-align: right">
                                            <v-btn
                                              width="110"
                                              @click="
                                                item.showSubmitAnsBtn = false
                                              "
                                              class="text-capitalize btnStyleCancel mr-3"
                                              >{{ $t("cancel") }}</v-btn
                                            >
                                            <v-btn
                                              width="110"
                                              @click="
                                                (item.showSubmitAnsBtn = false),
                                                  item.questionScore == ''
                                                    ? (item.questionScore = 0)
                                                    : item.questionScore
                                              "
                                              class="text-capitalize btnStyleSubmit mr-3 btn_hover_effect"
                                              color="#A6CC39"
                                              >{{ $t("submit") }}</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </v-list-item-group>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                            <v-row class="ma-1">
                              <v-col cols="12" md="6" lg="6">
                                <v-icon color="#4390DE" center
                                  >mdi-checkbox-outline</v-icon
                                >
                                <span
                                  @click="item.showSubmitAnsBtn = true"
                                  style="
                                    color: #4390de;
                                    font-size: 18px;
                                    margin-left: 10px;
                                    cursor: pointer;
                                  "
                                  >{{ $t("answer") }}</span
                                >
                                <span
                                  style="
                                    color: #424242;
                                    font-size: 16px;
                                    margin-left: 10px;
                                  "
                                  >{{
                                    "( " +
                                    item.questionScore +
                                    " " +
                                    $t("score") +
                                    ")"
                                  }}</span
                                >
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                lg="6"
                                v-if="
                                  CheckedChoice(
                                    choice_Items.filter(
                                      (x) => x.questionId == item.questionId
                                    )
                                  ) == false
                                    ? (item.ans_notSelect_err =
                                        notSelectAnsErrMessage)
                                    : (item.ans_notSelect_err = false)
                                "
                                style="text-align: right !important"
                              >
                                <span
                                  style="
                                    color: red;
                                    font-size: 18px;
                                    margin-left: 10px;
                                  "
                                  >{{
                                    "Please select the correct answer."
                                  }}</span
                                >

                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="CopyQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #a6cc39;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2">mdi-content-copy</v-icon>
                                  {{ $t("copy") }}
                                </v-btn>

                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="DeleteQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #ff6060;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2"
                                    >mdi-delete-outline</v-icon
                                  >
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-col>
                              <v-col
                                v-else
                                cols="12"
                                md="6"
                                lg="6"
                                style="text-align: right !important"
                              >
                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="CopyQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #a6cc39;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2">mdi-content-copy</v-icon>
                                  {{ $t("copy") }}
                                </v-btn>

                                <v-btn
                                  class="ml-8 text-capitalize"
                                  @click="DeleteQuestion(item)"
                                  style="
                                    background-color: transparent;
                                    box-shadow: unset;
                                    cursor: pointer;
                                    color: #ff6060;
                                    font-size: 18px;
                                  "
                                >
                                  <v-icon class="mr-2"
                                    >mdi-delete-outline</v-icon
                                  >
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row class="ma-1">
                      <v-col cols="12" style="text-align: center">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            style="
                              font-size: 18px;
                              border-radius: 8px;
                              color: #a6cc39;
                              box-shadow: unset;
                              background-color: #ffffff;
                            "
                            :style="{
                              border: hover
                                ? '1px solid #A6CC39'
                                : '1px solid #C0C0C0',
                            }"
                            class="mr-5 text-capitalize"
                            @click="AddSectionFromBtn"
                          >
                            <v-icon color="#A6CC39">mdi-plus</v-icon>
                            {{ $t("addsection") }}
                          </v-btn>
                        </v-hover>
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            style="
                              font-size: 18px;
                              border-radius: 8px;
                              color: #a6cc39;
                              box-shadow: unset;
                              background-color: #ffffff;
                            "
                            :style="{
                              border: hover
                                ? '1px solid #A6CC39'
                                : '1px solid #C0C0C0',
                            }"
                            class="ml-5 text-capitalize"
                            @click="AddQuestion()"
                          >
                            <v-icon color="#A6CC39">mdi-plus</v-icon>
                            {{ $t("addquestion") }}
                          </v-btn>
                        </v-hover>
                      </v-col>
                    </v-row>
                    <v-row class="ma-1">
                      <v-col style="text-align: right">
                        <v-btn
                          v-if="!QuizSave"
                          width="135"
                          class="text-capitalize btnStyle btn_hover_effect"
                          color="#a6cc39"
                          @click="SaveAddQuiz()"
                          >{{ $t("save") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-row class="ma-1 mt-3">
                <v-col>
                  <div
                    style="
                      width: 513px;
                      background: transparent;
                      border: 1px solid #c0c0c0;
                      border-radius: 8px;
                      height: 40px;
                      box-shadow: unset;
                      margin-top: 10px;
                      margin-left: 8px;
                    "
                  >
                    <p
                      style="
                        font-size: 18px;
                        margin-top: 5px;
                        margin-left: 17px;
                        color: #707070;
                      "
                    >
                      {{ quizName }}
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="10" md="10" lg="10">
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <b>

                        <p
                          style="
                            font-size: 18px;
                            margin-top: 0px;
                            margin-left: 8px;
                            margin-bottom: 0px;
                            color: #424242;
                          "
                        >
                          {{ $t("quizshowing") }}
                        </p>
                      </b>
                    </v-col>
                    <v-col cols="12" class="pt-1">
                      <v-radio-group
                        hide-details
                        class="mt-0 pt-0"
                        v-model="radioGroup"
                      >
                        <v-radio
                          v-for="n in radioItems"
                          :key="n.text"
                          :label="$t(n.text)"
                          :value="n.value"
                          color="#4FB14E"
                          style="
                            padding-left: 5px;
                            font-size: 14px;
                            height: 32px;
                          "
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12">
                  <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                      <b>
                          {{ $t("answerDisplay") }}
                    </b>
                    </p>
                  </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        • {{ $t("showSelectedAnswers") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center mb-4">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch1"
                          @input="
                            Show_correctAns_switch(
                              setting.showCorrectAnswerAfterCompletion
                            )
                          "
                          v-model="setting.showCorrectAnswerAfterCompletion"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch1">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center ">
                      <p class="settingTabSameText">
                        • {{ $t("showCorrectAnswers") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch2"
                          :disabled="!setting.showCorrectAnswerAfterCompletion"
                          v-model="setting.showCorrectAnswer"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch2">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- 2 -->
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12">
                  <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                      <b>
                          {{ $t("scoreDisplay") }}
                    </b>
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex align-center">
                        <p class="settingTabSameText">
                          • {{ $t("showObtainedScore") }}
                        </p>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex align-center">
                        <div class="onoffswitch ml-5">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            class="onoffswitch-checkbox"
                            id="showScoreSwitch"
                            v-model="setting.showScore"
                          />
                          <label class="onoffswitch-label" for="showScoreSwitch">
                            <div class="onoffswitch-inner"></div>
                            <div class="onoffswitch-switch"></div>
                          </label>
                        </div>
                      </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center mb-4">
                      <p class="settingTabSameText">
                        • {{ $t("showPassingScore") }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="d-flex align-center"
                      style="display: flex !important"
                    >
                      <div class="onoffswitch ml-5" @click="showErrorMessage">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch4"
                          :disabled="setting.passingScore == 0 ? true : false"
                          v-model="setting.showPassingScore"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch4">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                      <div class="mt-3 pl-4" v-if="errorMessage">
                        <span class="passingScore">{{
                          $t("specifyPassingscore")
                        }}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center ">
                      <p class="settingTabSameText">
                        • {{ $t("specifyPassingScore") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        type="number"
                        :class="{ 'red-border': showRedBorder }"
                        v-model="setting.passingScore"
                        style="width: 70px; height: 32px"
                        @input="checkPassingScore(setting.passingScore)"
                      ></v-text-field>
                      <p class="settingTabSameText">
                        {{ $t("score") }} 
                      </p>
                    </v-col>
                   
                  </v-row>
                  <!-- 3 -->
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12">
                  <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                      <b>
                          {{ $t("randomizationSetting") }}
                    </b>
                    </p>
                  </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        • {{ $t("randomizeQuestionsAndChoices") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                          <div class="onoffswitch ml-5">
                            <input
                              type="checkbox"
                              name="onoffswitch"
                              class="onoffswitch-checkbox"
                              id="randomQuestionSwitch"
                              v-model="setting.randomQuestionChoice"
                            />
                            <label class="onoffswitch-label" for="randomQuestionSwitch">
                              <div class="onoffswitch-inner"></div>
                              <div class="onoffswitch-switch"></div>
                            </label>
                          </div>
                        </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center mb-4">
                      <p class="settingTabSameText">
                        • {{ $t("randomlyanswer") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="2" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        type="number"
                        :class="{ 'red-border': showRedBorder }"
                        v-model="setting.randomAnswer"
                        style="width: 70px; height: 32px"
                        @input="checkRandomAnswer(setting.randomAnswer)"
                      ></v-text-field>
                      <p class="settingTabSameText">
                        {{ $t("questionoutof") }} {{totalQuestions}}
                      </p>
                    </v-col>
                  </v-row>
                  <!-- 4 -->
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12">
                    <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                      <b>
                          {{ $t("timeLimitSettings") }}
                    </b>
                    </p>
                  </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        • {{ $t("specifyTimeLimit") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <v-menu transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="70"
                            height="32"
                            v-bind="attrs"
                            v-on="on"
                            style="
                              border: 1px solid #c0c0c0;
                              opacity: 1;
                              font-size: 16px;
                              padding: 0 8px !important;
                            "
                            class="ml-6"
                            outlined
                          >
                            {{ setting.timer }}
                            <v-icon class="d-flex ml-auto"
                              >mdi-unfold-more-horizontal</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, i) in timerItems"
                            :key="i"
                            @click="setting.timer = item.minute"
                            style="min-height: 32px !important"
                          >
                            <v-list-item-title class="d-flex justify-center">{{
                              item.minute
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <p class="settingTabSameText">
                        {{ $t("time").toLowerCase() }}
                      </p>
                    </v-col>
                    
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12">
                    <p
                        style="
                          font-size: 18px;
                          margin-top: 0px;
                          margin-left: 8px;
                          margin-bottom: 0px;
                          color: #424242;
                        "
                      >
                      <b>
                          {{ $t("submissionSettings") }}
                    </b>
                    </p>
                  </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        • {{ $t("limitedSubmit") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        value="5"
                        type="number"
                        v-model="setting.limitedSubmit"
                        style="width: 70px; height: 32px"
                      ></v-text-field>
                      <p class="settingTabSameText">
                        {{ $t("time").toLowerCase() }}
                      </p>
                    </v-col>
                    
                  </v-row>
                  <!-- //--------- -->
                  <!-- <v-row no-gutters class="py-3 pl-4">
                    
                  </v-row> -->
                  <!-- <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showanswerresultsonmobilecorrectwrong") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch1"
                          @input="
                            Show_correctAns_switch(
                              setting.showCorrectAnswerAfterCompletion
                            )
                          "
                          v-model="setting.showCorrectAnswerAfterCompletion"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch1">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row> -->
                  <!-- <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showcorrectanswerincaseofwronganswer") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch2"
                          :disabled="!setting.showCorrectAnswerAfterCompletion"
                          v-model="setting.showCorrectAnswer"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch2">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showscore") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <div class="onoffswitch ml-5">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch3"
                          v-model="setting.showScore"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch3">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-3 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <p class="settingTabSameText">
                        {{ $t("showpassingscore") }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="d-flex align-center"
                      style="display: flex !important"
                    >
                      <div class="onoffswitch ml-5" @click="showErrorMessage">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch4"
                          :disabled="setting.passingScore == 0 ? true : false"
                          v-model="setting.showPassingScore"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch4">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
                      <div class="mt-3 pl-4" v-if="errorMessage">
                        <span class="passingScore">{{
                          $t("specifyPassingscore")
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-2 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-end">
                      <p class="settingTabSameText">
                        {{ $t("passingscore") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        type="number"
                        :class="{ 'red-border': showRedBorder }"
                        v-model="setting.passingScore"
                        style="width: 70px; height: 32px"
                        @input="checkPassingScore(setting.passingScore)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-2 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-end">
                      <p class="settingTabSameText">
                        {{ $t("limitedsubmit") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center">
                      <v-text-field
                        solo
                        class="ml-6 settingTextBox"
                        hide-spin-buttons
                        hide-details
                        value="5"
                        type="number"
                        v-model="setting.limitedSubmit"
                        style="width: 70px; height: 32px"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="py-2 pl-4">
                    <v-col cols="12" md="6" class="d-flex align-end">
                      <p class="settingTabSameText">
                        {{ $t("set-timer") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <v-menu transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="70"
                            height="32"
                            v-bind="attrs"
                            v-on="on"
                            style="
                              border: 1px solid #c0c0c0;
                              opacity: 1;
                              font-size: 16px;
                              padding: 0 8px !important;
                            "
                            class="ml-6"
                            outlined
                          >
                            {{ setting.timer }}
                            <v-icon class="d-flex ml-auto"
                              >mdi-unfold-more-horizontal</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, i) in timerItems"
                            :key="i"
                            @click="setting.timer = item.minute"
                            style="min-height: 32px !important"
                          >
                            <v-list-item-title class="d-flex justify-center">{{
                              item.minute
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <p class="settingTabSameText">
                        {{ $t("minutes").toLowerCase() }}
                      </p>
                    </v-col>
                  </v-row> -->

                  <v-row>
                    <v-col style="text-align: right">
                      <v-btn
                        width="135"
                        class="text-capitalize btnStyle btn_hover_effect mr-3"
                        style="
                          color: #ffff;
                          border: 1px solid #a6cc39;
                          border-radius: 8px;
                        "
                        color="#A6CC39"
                        @click="SaveSetting()"
                        >{{ $t("save") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <ShowResponseTab v-if="currentTab === 2" :quizName="quizName" />
            </v-tab-item>
            <v-tab-item value="tab-4">
              <AddCertificateTab
                v-if="currentTab === 3"
                :quizName="quizName"
                :quizLocalId="quizId"
              />
            </v-tab-item>
            <v-tab-item value="tab-5">
              <NotificationTab v-if="currentTab === 4" :quizLocalId="quizId" />
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-card>
    </v-container>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="#4caf50" dark>
        <v-card-text class="white--text">
          {{ $t("loadingpleasewait") }}
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCrop"
      persistent
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 40px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            @click="CancelCrop()"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style btn_hover_effect"
            width="100"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input
      type="file"
      accept="image/*"
      class="d-none"
      ref="file"
      @change="uploadImg($event, 1, fileQuestionSelectquestionId, true)"
    />

    <input
      type="file"
      accept="image/*"
      class="d-none"
      ref="fileChoice"
      @change="uploadImg($event, 1, fileChoiceSelectchoiceId, false)"
    />
  </div>
</template>

<script>
import axios from "axios";
import { uuid } from "vue-uuid";
import * as moment from "moment/moment";
import { VueCropper } from "vue-cropper";
import AddCertificateTab from "./mini-parts/AddCertificateTab.vue";
import ShowResponseTab from "./mini-parts/ShowResponseTab.vue";
import NotificationTab from "./mini-parts/NotificationTab.vue";

export default {
  components: {
    VueCropper,
    AddCertificateTab,
    ShowResponseTab,
    NotificationTab,
  },

  data: () => ({
    totalQuestions:"",
    member_Id: null,
    radioGroup: 1,
    radioItems: [
      { value: 1, text: "finishwatchingassignment" },
      { value: 2, text: "watchpartofthecontent" },
      { value: 3, text: "anytime" },
    ],
    loading: false,
    showRedBorder: false,
    timerItems: [
      { minute: 0 },
      { minute: 10 },
      { minute: 20 },
      { minute: 30 },
      { minute: 40 },
      { minute: 50 },
      { minute: 60 },
      { minute: 90 },
      { minute: 120 },
    ],
    setting: {},
    errorMessage: false,
    dialogCrop: false,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    uploadOption: {
      id: null,
      question: null,
      fileName: null,
    },
    selectOptionTypes: [
      { value: false, text: "singlechoice" },
      { value: true, text: "multiplechoice" },
    ],
    uuid: uuid.v1(),
    quizId: uuid.v4(),
    notSelectAnsErrMessage: false,
    choice_Items: [
      {
        show_imgBtn: false, //show image in each row
        choiceNo: 1,
        choice: "Option",
        choiceImge: null,
        choiceAnswerMaster: null,
        userAnswer: null,
        rule: false,
      },
    ],
    fileChoiceSelectchoiceId: null,
    fileQuestionSelectquestionId: null,
    question_Items: [],
    section_Items: [],
    quizName: "",
    valid: false,
    Tabs: [
      { title: "questionsm" },
      { title: "setting" },
      { title: "response" },
      { title: "certificate" },
      { title: "notification" },
    ],
    QuizSave: false,
    previousTab: 0,
    currentTab: 0,
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "quiz",
        href: "/home/quiz",
      },
      {
        text: "questionsm",
      },
    ],
  }),

  computed: {
    quizSectionRules() {
      return [(v) => !!v || "Section is required"];
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    quizNameRules() {
      return [(v) => !!v || "Quiz Name is required"];
    },
    tabs: {
      get() {
        switch (this.$store.getters.tabs) {
          case "tab-1":
            break;
          case "tab-2":
            this.GetQuizSetting();
            //this.getAllAssignmentDropDwonForQuiz();
            break;
          case "tab-3":
            break;
          case "tab-4":
            break;
          case "tab-5":
            break;
        }
        return this.$store.getters.tabs;
      },
      set() {
        this.$store.commit("changeTab", this.tabValue);
      },
    },
  },

  mounted() {
    this.$store.commit("changeTab", "tab-1");
    this.AddSection();
    this.AddQuestion();
    const userData = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    this.member_Id = userData.memberId;
  },

  methods: {
    async SaveSetting() {
      let that = this;
      that.loading = true;
      if (that.radioGroup == 1) {
        that.setting.showQuizFinishWatching = true;
        that.setting.showQuizAfterWatching = false;
        that.setting.showQuizWhenever = false;
      } else if (that.radioGroup == 2) {
        that.setting.showQuizFinishWatching = false;
        that.setting.showQuizAfterWatching = true;
        that.setting.showQuizWhenever = false;
      } else if (that.radioGroup == 3) {
        that.setting.showQuizFinishWatching = false;
        that.setting.showQuizAfterWatching = false;
        that.setting.showQuizWhenever = true;
      } else {
        that.setting.showQuizFinishWatching = false;
        that.setting.showQuizAfterWatching = false;
        that.setting.showQuizWhenever = false;
      }
      that.setting.limitedSubmit = parseInt(that.setting.limitedSubmit);
      that.setting.passingScore = parseInt(that.setting.passingScore);
      that.setting.timer = parseInt(that.setting.timer);
      that.setting.randomAnswer=parseInt(that.setting.randomAnswer);
      that.setting.updateBy = that.memberid;
      that.setting.courseId = that.courseOfQuiz?.[0]?.id ?? null;

      await axios
        .post(`${that.web_urlV4}Quiz/EditQuizSetting`, that.setting)
        .then(function (response) {
          if (response.data.status == 0) {
            that.courseId = that.courseOfQuiz?.[0]?.id ?? null;
            if (that.courseId != null && that.courseId != undefined) {
              alert("Save Success both setting and Course.");
            }
            that.$store.commit("changeTab", "tab-4");
            that.currentTab = 3;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    Show_correctAns_switch(v) {
      if (v) {
        this.setting.showCorrectAnswer = false;
      }
    },

    check_duplicate(item, choice) {
      let choicetemp = this.choice_Items;
      let check =
        choicetemp.filter(
          (x) =>
            x.choice == choice &&
            choice != null &&
            x.questionId == item.questionId
        ).length > 1
          ? true
          : false;

      if (check) {
        this.choice_Items.find((x) => x.choiceId === item.choiceId).rule = true;
      } else {
        this.choice_Items.find(
          (x) => x.choiceId === item.choiceId
        ).rule = false;
      }
    },

    showErrorMessage() {
      if (this.setting.passingScore == 0) {
        this.errorMessage = true;
        this.showRedBorder = true;
      } else {
        this.errorMessage = false;
        this.showRedBorder = false;
      }
    },
    checkPassingScore(v) {
      let self = this;
      if (/^0\d+/.test(v)) {
        alert("Please enter a valid score without leading zeros.");
        self.$nextTick(() => {
          self.setting.passingScore = 0;
          self.setting.showPassingScore = false;
        });
      } else if (v > self.totalScoreInSetting) {
        alert("Please enter the minimum score again.");
        self.$nextTick(() => {
          self.setting.passingScore = 0;
          self.setting.showPassingScore = false;
        });
      } else if (v == 0) {
        self.setting.showPassingScore = false;
        self.errorMessage = true;
      } else {
        self.errorMessage = false;
        self.showRedBorder = false;
      }
    },
    checkRandomAnswer(v) {
      let self = this;
      console.log("self.totalQuestions",self.totalQuestions);
      console.log("self.input data",v);
  
  if (/^0\d+/.test(v)) {
    alert("Please enter a valid value without leading zeros.");
    self.$nextTick(() => {
      self.setting.randomAnswer=0;
      self.setting.randomQuestionChoice = false;
      self.setting.showRandomQuestionChoice = false;
    });
  } else if (v < 0 || v > self.totalQuestions) {
   
    alert("Please enter a valid number of random questions.");
    self.$nextTick(() => {
      self.setting.randomAnswer=0;
      self.setting.randomQuestionChoice = false;
      self.setting.showRandomQuestionChoice = false;
    });
  } else if (v == 0) {
    self.setting.showRandomQuestionChoice = false;
    self.errorMessageRandom = true;
  } else {
    self.errorMessageRandom = false;
    self.showRedBorder = false;
  }
},
    async getAllAssignmentDropDwonForQuiz() {
      let that = this;
      that.loading = true;

      await axios
        .get(
          `${that.web_url}Assignment/getAllAssignmentDropDwonForQuiz?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.AssignmentDropDwonForQuiz = response.data.data.map((v) => ({
              ...v,
              startDateTime: moment(v.startDateTime)
                .local()
                .format("DD/MM/YYYY"),
              endDateTime: moment(v.endDateTime).local().format("DD/MM/YYYY"),
            }));

            that.courseOfQuiz = [];
            if (that.courseId != undefined && that.courseId != null) {
              let selectedTemp = that.AssignmentDropDwonForQuiz.filter(
                (x) => x.id == that.courseId
              )?.[0];
              if (selectedTemp != undefined) {
                that.courseOfQuiz.push(selectedTemp);
              }
            }
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    async GetQuizSetting() {
      let that = this;
      // that.itemsList = [];
      that.loading = true;
      // const userdata = JSON.parse(
      //   localStorage.getItem("vivek_authenticated_user_data")
      // );
      // const memberid = userdata.memberId;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizId,
        createOrUpdateBy: that.member_Id,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizSetting`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.setting = response.data.data;
            if (that.setting.showQuizFinishWatching === true) {
              that.radioGroup = 1;
            } else if (that.setting.showQuizAfterWatching === true) {
              that.radioGroup = 2;
            } else if (that.setting.showQuizWhenever === true) {
              that.radioGroup = 3;
            }

            that.setting.passingScore =
              response.data.data.passingScore != null
                ? response.data.data.passingScore
                : 0;

            that.setting.showPassingScore =
              that.setting.passingScore == 0
                ? false
                : that.setting.showPassingScore;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    async GetQuizMasterDetail() {
      let that = this;
      // that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        startCreateDate: null,
        endCreateDate: null,
        quizId: that.quizId,
      };
      
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizMasterDetailWeb`, request)
        .then(function (response) {
          console.log("hello response data data",response.data.data.questionList);
          console.log("hello response data data",response.data.data.questionList.length);
          if (response.data.status == 0) {
            
            that.section_Items = response.data.data.sectionList;
            that.question_Items = response.data.data.questionList;
            that.choice_Items = response.data.data.choiceList;
            that.quizName = response.data.data.quizName;
            that.quizId = response.data.data.quizId;

            // Ensure length is valid before assignment
      if (Array.isArray(response.data.data.questionList)) {
        that.totalQuestions = response.data.data.questionList.length;
        console.log("Assigned totalQuestions:", that.totalQuestions);
      }

            
          }
      
          that.totalScoreInSetting = that.question_Items
            .map((v) => v.questionScore)
            .reduce((accu, v) => parseInt(accu) + parseInt(v));
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    async SaveAddQuiz() {
      console.log("hello");
      let that = this;
      that.loading = true;
      if (that.$refs.form1.validate()) {
        let request = {
          quizId: that.quizId,
          courseId: null,
          userId: 0,
          companyId: localStorage.getItem("companyID"),
          quizName: that.quizName,
          courseName: null,
          createOrUpdateBy: that.member_Id,
          sectionList: [],
        };
        console.log("hello request",request);
        for (let sectionTemp of that.section_Items) {
          let queTemp = that.question_Items.filter(
            (x) => x.sectionId == sectionTemp.sectionId
          );
          let choTemp = that.choice_Items.filter(
            (x) => x.sectionId == sectionTemp.sectionId
          );

          let sec = {
            sectionId: sectionTemp.sectionId,
            sectionNo: 0,
            sectionName: sectionTemp.sectionName,
            sectionDescription: sectionTemp.sectionDescription,
            questionList: [],
          };
          console.log("hello quiz list sec",sec);
          if (queTemp.length == 0) {
            alert("Please add question in section");
            that.loading = false;
            return;
          }

          for (let elementQue of queTemp) {
            let que = {
              questionId: elementQue.questionId,
              questionNo: 0,
              questionScore: parseInt(elementQue.questionScore),
              question: elementQue.question,
              questionImge: elementQue.questionImge,
              multipleChoice: elementQue.multipleChoice,
              choiceList: [],
            };

            if (
              (elementQue?.question === null || elementQue?.question === "") &&
              elementQue?.questionImge === null
            ) {
              alert("Please fill in question or add images");
              that.loading = false;
              return;
            }

            let choFilter = choTemp.filter(
              (x) => x.questionId == elementQue.questionId
            );
            let checkChoiceAnswerMaster = true;
            let checkChoice = false;
            let duplicate_option = false;

            for (let elementCho of choFilter) {
              que.choiceList.push({
                choiceId: elementCho.choiceId,
                choiceNo: 0,
                choice: elementCho.choice,
                choiceImge: elementCho.choiceImge,
                choiceAnswerMaster: elementCho.choiceAnswerMaster,
              });

              if (
                (elementCho.choice == null ||
                  elementCho.choice == undefined ||
                  elementCho.choice == "") &&
                (elementCho.choiceImge == null ||
                  elementCho.choiceImge == undefined ||
                  elementCho.choiceImge == "")
              ) {
                checkChoice = true;
              }
              if (elementCho.choiceAnswerMaster === true) {
                checkChoiceAnswerMaster = false;
                that.notSelectAnsErrMessage = false;
              }

              if (elementCho.rule) {
                duplicate_option = true;
              }
            }

            if (checkChoice) {
              alert("Please fill in choice or add images");
              that.loading = false;
              return;
            }
            if (duplicate_option) {
              alert("Duplicate options Please enter a new option.");
              that.loading = false;
              return;
            }

            if (checkChoiceAnswerMaster) {
              that.notSelectAnsErrMessage = true;
              that.loading = false;
              return;
            }
            sec.questionList.push(que);
          }

          const uniqueValues = new Set(queTemp.map((v) => v.question));

          if (uniqueValues.size < queTemp.length) {
            let ques_null = queTemp.filter(
              (x) => x.question == null || x.question == ""
            );
            let image_null = queTemp.filter((x) => x.questionImge == null);

            if (ques_null.length > 0 && image_null.length > 0) {
              // both are null
              alert("Please fill in Questions or add Images.");
              that.loading = false;
              return;
            } else if (ques_null.length == 0) {
              // no null in question
              alert("Duplicate Questions Please enter a new one.");
              that.loading = false;
              return;
            }
          }
       
          request.sectionList.push(sec);
        }
        console.log("add quiz, ", request);
        await axios
          .post(`${that.web_urlV4}Quiz/AddQuizMaster`, request)
          .then(function (response) {
            if (response.data.status == 0) {
              alert("Save Success");
              that.QuizSave = true;
              that.quizId = response.data.data.quizId;
              localStorage.setItem("QuizId", that.quizId);
              that.$store.commit("changeTab", "tab-2");
              that.currentTab = 1;
              that.GetQuizMasterDetail();
            }
          })
          .catch(function (err) {
            that.loading = false;
            throw err;
          });
      }
      that.loading = false;
    },

    scrollToTop(item) {
      let indexAll = [];
      for (let element of this.question_Items) {
        if (element.sectionId == item.sectionId) {
          const indexQuestionAll = this.question_Items.findIndex(
            (question_Items) => question_Items.questionId == element.questionId
          );
          indexAll.push(indexQuestionAll);
        }
      }

      if (indexAll.length > 0) {
        const indexQuestion = this.question_Items.findIndex(
          (question_Items) => question_Items.questionId == item.questionId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexQuestion);

        if (indexAll[indexPosition - 1] != undefined) {
          this.question_Items.splice(indexQuestion, 1);
          this.question_Items.splice(indexAll[indexPosition - 1], 0, item);
        }
      }
    },
    scrollToBottom(item) {
      let indexAll = [];
      for (let element of this.question_Items) {
        if (element.sectionId == item.sectionId) {
          const indexQuestionAll = this.question_Items.findIndex(
            (question_Items) => question_Items.questionId == element.questionId
          );
          indexAll.push(indexQuestionAll);
        }
      }

      if (indexAll.length > 0) {
        const indexQuestion = this.question_Items.findIndex(
          (question_Items) => question_Items.questionId == item.questionId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexQuestion);

        if (indexAll[indexPosition + 1] != undefined) {
          this.question_Items.splice(indexQuestion, 1);
          this.question_Items.splice(indexAll[indexPosition + 1], 0, item);
        }
      }
    },

    DeleteQuestion(item) {
      let that = this;
      const index = that.question_Items.findIndex(
        (question_Items) => question_Items.questionId == item.questionId
      );
      for (let i = that.choice_Items.length; i--; ) {
        if (this.choice_Items[i].questionId === item.questionId)
          that.choice_Items.splice(i, 1);
      }
      that.question_Items.splice(index, 1);
    },

    AddSectionFromBtn() {
      this.AddSection();
      this.AddQuestion();
    },

    Delete_Option(item) {
      const index = this.choice_Items.findIndex(
        (question_Items) => question_Items.choiceId == item.choiceId
      );
      this.choice_Items.splice(index, 1);
    },

    Add_Option(item) {
      let choiceTemp = {
        choiceId: uuid.v4(),
        companyId: item.companyId,
        quizId: item.quizId,
        sectionId: item.sectionId,
        questionId: item.questionId,
        choiceNo: 1,
        choice: null,
        choiceImge: null,
        choiceAnswerMaster: false,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
        rule: false,
      };
      this.choice_Items.push(choiceTemp);
    },

    AddSection() {
      let section_Item = {
        sectionId: uuid.v4(),
        companyId: localStorage.getItem("companyID"),
        quizId: this.quizId,
        sectionNo: 1,
        sectionName: null,
        sectionDescription: null,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
      };
      this.section_Items.push(section_Item);
    },

    AddQuestion() {
      let section_Item = this.section_Items[this.section_Items.length - 1];
      let questionTemp = {
        questionId: uuid.v4(),
        companyId: section_Item.companyId,
        quizId: section_Item.quizId,
        sectionId: section_Item.sectionId,
        questionNo: 1,
        multipleChoice: false,
        questionScore: 1, //5,
        question: null,
        questionImge: null,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
        showSubmitAnsBtn: false, //to show answer's submit button separately
        ans_notSelect_err: false,
      };
      this.question_Items.push(questionTemp);
      this.notSelectAnsErrMessage = false;

      let choiceTemp1 = {
        choiceId: uuid.v4(),
        companyId: questionTemp.companyId,
        quizId: questionTemp.quizId,
        sectionId: questionTemp.sectionId,
        questionId: questionTemp.questionId,
        choiceNo: 1,
        choice: null,
        choiceImge: null,
        choiceAnswerMaster: false,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
        rule: false,
      };
      this.choice_Items.push(choiceTemp1);
    },
    CopyQuestion(item) {
      const index = this.question_Items.findIndex(
        (question_Items) => question_Items.questionId == item.questionId
      );

      let question_Item = this.question_Items.filter(
        (x) => x.questionId == item.questionId
      )[0];

      let nameQuizAfterCheck = null;
      if (
        question_Item?.question != null &&
        question_Item?.question != undefined &&
        question_Item?.question != ""
      ) {
        let CheckQuizName = false;
        for (let i = 1; CheckQuizName == false; i++) {
          let CheckQuizNameFrist = this.question_Items.filter(
            (x) => x.question == question_Item.question + "-copy(" + i + ")"
          )[0];
          if (CheckQuizNameFrist == null) {
            nameQuizAfterCheck = question_Item.question + "-copy(" + i + ")";
            CheckQuizName = true;
          }
        }
      } else {
        nameQuizAfterCheck = question_Item.question;
      }

      let questionTemp = {
        questionId: uuid.v4(),
        companyId: question_Item.companyId,
        quizId: question_Item.quizId,
        sectionId: question_Item.sectionId,
        questionNo: question_Item.questionNo,
        multipleChoice: question_Item.multipleChoice,
        questionScore: question_Item.questionScore,
        question: nameQuizAfterCheck,
        questionImge: question_Item.questionImge,
        createBy: question_Item.createBy,
        createDate: question_Item.createDate,
        updateBy: question_Item.updateBy,
        updateDate: question_Item.updateDate,
        activeFlag: question_Item.activeFlag,
        showSubmitAnsBtn: question_Item.showSubmitAnsBtn,
        ans_notSelect_err: false,
      };
      this.question_Items.splice(index + 1, 0, questionTemp);

      let choice_Item = this.choice_Items.filter(
        (x) => x.questionId == item.questionId
      );
      for (let element of choice_Item) {
        let choiceTemp = {
          choiceId: uuid.v4(),
          companyId: questionTemp.companyId,
          quizId: questionTemp.quizId,
          sectionId: questionTemp.sectionId,
          questionId: questionTemp.questionId,
          choiceNo: element.choiceNo,
          choice: element.choice,
          choiceImge: element.choiceImge,
          choiceAnswerMaster: element.choiceAnswerMaster,
          createBy: element.createBy,
          createDate: element.createDate,
          updateBy: element.updateBy,
          updateDate: element.updateDate,
          activeFlag: element.activeFlag,
          rule: false,
        };

        this.choice_Items.push(choiceTemp);
      }
    },

    CheckedChoice(choices) {
      let show_errMsg = choices.find((x) => x.choiceAnswerMaster === true)
        ? true
        : false;
      return show_errMsg;
    },
    SelectChooseAnswerType(item) {
      if (item.multipleChoice === false) {
        for (let element of this.choice_Items) {
          if (element.questionId == item.questionId) {
            element.choiceAnswerMaster = false;
          }
        }
      }
    },
    CheckChooseAnswerType(item) {
      let that = this;
      that.question_Items.find(
        (x) => x.questionId == item.questionId
      ).showSubmitAnsBtn = true;
      let question_Item = that.question_Items.filter(
        (x) => x.questionId == item.questionId
      )[0];

      if (question_Item.multipleChoice === false) {
        for (let element of that.choice_Items) {
          if (
            element.questionId == item.questionId &&
            element.choiceId != item.choiceId
          ) {
            element.choiceAnswerMaster = false;
          }

          if (element.choiceId == item.choiceId) {
            element.choiceAnswerMaster = item.choiceAnswerMaster;

            if (that.notSelectAnsErrMessage === true) {
              item.ans_notSelect_err = item.choiceAnswerMaster;
            } else {
              item.ans_notSelect_err = false;
            }
          }
        }
      } else if (item.choiceAnswerMaster === true) {
        item.ans_notSelect_err = false;
      }
    },
    DeleteSection(item) {
      let that = this;
      const index = that.section_Items.findIndex(
        (section_Items) => section_Items.sectionId == item.sectionId
      );
      for (let i = that.choice_Items.length; i--; ) {
        if (this.choice_Items[i].sectionId === item.sectionId) {
          that.choice_Items.splice(i, 1);
        }
      }
      for (let j = that.question_Items.length; j--; ) {
        if (this.question_Items[j].sectionId === item.sectionId) {
          that.question_Items.splice(j, 1);
        }
      }
      that.section_Items.splice(index, 1);
    },
    WhereQuestion(sectionId) {
      return this.question_Items.filter((x) => x.sectionId == sectionId);
    },
    WhereChoice(questionId) {
      return this.choice_Items.filter((x) => x.questionId == questionId);
    },
    async onTabChange(clickedTab) {
      this.currentTab = clickedTab;
      await this.$nextTick();

      if (clickedTab == 0) {
        this.currentTab = 0;
        this.previousTab = 0;
        this.$store.commit("changeTab", "tab-1");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home", //home
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "questionsm",
          },
        ];
      } else if (clickedTab == 1) {
        this.currentTab = 1;
        this.previousTab = 1;
        this.$store.commit("changeTab", "tab-2");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home", //home
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "setting",
          },
        ];
      } else if (clickedTab == 2) {
        this.currentTab = 2;
        this.previousTab = 2;
        this.$store.commit("changeTab", "tab-3");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home",
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "response",
          },
        ];
      } else if (clickedTab == 3) {
        this.currentTab = 3;
        this.previousTab = 3;
        this.$store.commit("changeTab", "tab-4");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home",
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "certificate",
          },
        ];
      } else if (clickedTab == 4) {
        this.currentTab = 4;
        this.previousTab = 4;
        this.$store.commit("changeTab", "tab-5");
        this.breadcrumbs = [
          {
            sidebar_tilte: "home",
          },
          {
            text: "quiz",
            href: "/home/quiz",
          },
          {
            text: "notification",
          },
        ];
      }
    },
    // async onTabChange(clickedTab) {
    //   this.currentTab = clickedTab;
    //   await this.$nextTick();

    //   if (clickedTab == 0) {
    //     this.currentTab = 0;
    //     this.previousTab = 0;
    //     this.$store.commit("changeTab", "tab-1");
    //     this.breadcrumbs = [
    //       {
    //         sidebar_tilte: "home", //home
    //       },
    //       {
    //         text: "quiz",
    //         href: "/home/quiz",
    //       },
    //       {
    //         text: "questionsm",
    //       },
    //     ];
    //   } else if (clickedTab == 1) {
    //     if (this.QuizSave == false) {
    //       this.currentTab = 0; // this.previousTab;
    //       alert("Save Question first.");
    //     } else {
    //       this.currentTab = 1;
    //       this.previousTab = 1;
    //       this.$store.commit("changeTab", "tab-2");
    //       this.breadcrumbs = [
    //         {
    //           sidebar_tilte: "home", //home
    //         },
    //         {
    //           text: "quiz",
    //           href: "/home/quiz",
    //         },
    //         {
    //           text: "setting",
    //         },
    //       ];
    //     }
    //   } else if (clickedTab == 2) {
    //     this.currentTab = 2;
    //     this.previousTab = 2;
    //     this.$store.commit("changeTab", "tab-3");
    //     this.breadcrumbs = [
    //       {
    //         sidebar_tilte: "home",
    //       },
    //       {
    //         text: "quiz",
    //         href: "/home/quiz",
    //       },
    //       {
    //         text: "response",
    //       },
    //     ];
    //   } else if (clickedTab == 3) {
    //     if (this.QuizSave == false) {
    //       this.currentTab = 0;
    //       alert("Save Question first.");
    //       this.$store.commit("changeTab", "tab-1");
    //     } else {
    //       this.currentTab = 3;
    //       this.previousTab = 3;
    //       this.$store.commit("changeTab", "tab-4");
    //       this.breadcrumbs = [
    //         {
    //           sidebar_tilte: "home",
    //         },
    //         {
    //           text: "quiz",
    //           href: "/home/quiz",
    //         },
    //         {
    //           text: "certificate",
    //         },
    //       ];
    //     }
    //   } else if (clickedTab == 4) {
    //     if (this.QuizSave === false) {
    //       this.currentTab = 0; // this.previousTab;
    //       alert("Save Question and Setting first.");
    //     }
    //     // else if (
    //     //   (this.QuizSave === true && this.CourseSave === false) ||
    //     //   this.courseId == null ||
    //     //   this.courseId == undefined
    //     // ) {
    //     //   this.currentTab = 1;
    //     //   alert("Save Assignment Course first");
    //     // }
    //     else {
    //       this.currentTab = 4;
    //       this.previousTab = 4;
    //       this.$store.commit("changeTab", "tab-5");
    //       this.breadcrumbs = [
    //         {
    //           sidebar_tilte: "home",
    //         },
    //         {
    //           text: "quiz",
    //           href: "/home/quiz",
    //         },
    //         {
    //           text: "notification",
    //         },
    //       ];
    //     }
    //   }
    // },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.UploadFileStringBase(data);
        this.dialogCrop = false;
      });
    },

    async UploadFileStringBase(data) {
      let that = this;
      that.loading = true;
      let request = {
        base64: data,
        fileName: that.uploadOption.fileName,
      };
      await axios
        .post(`${that.web_url}File/UploadFileStringBaseQuiz`, request)
        .then(function (response) {
          if (that.uploadOption.question === true) {
            that.question_Items.filter(
              (x) => x.questionId == that.uploadOption.id
            )[0].questionImge = response.data;
          } else {
            that.choice_Items.filter(
              (x) => x.choiceId == that.uploadOption.id
            )[0].choiceImge = response.data;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    uploadImg(e, num, id, question) {
      let self = this;
      let file = e.target.files[0];
      self.uploadOption.id = id;
      self.uploadOption.question = question;
      self.uploadOption.fileName = file.name;

      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style scoped>
/* inside question option card */
::v-deep
  .field_Style.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}
::v-deep .field_Style.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep .field_Style.v-input__slot {
  width: 98% !important;
}
::v-deep .field_Style.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  width: 98%;
}

::v-deep .option_sheet.v-list-item-group .v-list-item--active {
  color: #ffff;
}
/* ...... */
::v-deep
  .v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-input--selection-controls.v-input--checkbox {
  color: #4fb14e;
}
::v-deep
  .quiz_select.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: unset;
  border: 1px solid #707070;
  border-radius: 7px;
}
::v-deep .limited-submit.v-text-field.v-text-field--solo .v-input__control {
  min-height: 27px;
  padding: 0;
}
::v-deep .field_Style_1.primary--text {
  color: red !important;
  caret-color: red !important ;
}
::v-deep .field_Style_1.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep
  .field_Style_1.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: red;
  border-style: 1px solid !important;
  border-width: thin !important;
}
::v-deep .field_Style_1.v-input__slot {
  width: 98% !important;
}
::v-deep .mdi-alert::before {
  color: red;
}
.passingScore {
  font-weight: bold;
  font-size: 13px;
  color: #ff0000;
}

::v-deep
  .settingTextBox.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: unset !important;
  font-size: 16px;
  min-width: 70px !important;
  min-height: 32px;
  border: 1px solid #c0c0c0;
}
.settingTabSameText {
  font-size: 16px;
  margin-left: 10px;
  color: #424242;
  margin-bottom: 0;
}

.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #6fdc42;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
::v-deep .v-tab--active {
  background-color: #ffffff !important;
  color: #a6cc39 !important;
}
.font-style {
  font-size: 16px;
  color: #424242;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .btnStyle {
  font-size: 16px;
  height: 40px;
  color: #ffffff;
  border: 1px solid #a6cc39;
  border-radius: 8px;
}
.smallBtn.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 12px;
  border-radius: 5px;
}
::v-deep .v-tabs-slider-wrapper {
  height: 3px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}

::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
::v-deep .Image_Btn.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: -10px;
}
::v-deep .Image_Btn.v-btn--fab.v-size--x-small {
  height: 25px;
  width: 25px;
}
::v-deep .Image_Btn.v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
  top: -12px;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: unset;
  }
}
</style>
